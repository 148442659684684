<template>
  <div id="mail" class="py-4 px-3">
    <!-- start title -->
    <div class="mb-4">
      <page-header
          :title="$t('chats.title')"
          :subTitle="$t('chats.sub_title')"
      />
    </div>
    <div class="my-2">
      <div class="d-flex flex-wrap">
        <chat-window
            :current-user-id="currentUserId"
            :messages="messages"
            :rooms="rooms"
            :show-add-room="false"
            :load-first-room="true"
            :messages-loaded="true"
            :rooms-loaded="loadingRooms"
            :room-info-enabled="false"
            :emojis-suggestion-enabled="false"
            :show-reaction-emojis="false"
            :show-new-messages-divider="false"
            :show-audio="false"
            :show-emojis="false"
            :message-actions="[]"
            :room-actions="[]"
            :accepted-files="'image/png, image/jpeg'"
            @send-message="sendMessage"
            @fetch-messages="fetchMessages"
            height="80vh"
            :text-messages="{
            TYPE_MESSAGE: $t('chats.enter_message'),
          }"
        >
          <template #no-room-selected="{}">
            <div
                class="d-flex justify-content-center align-items-center py-5"
                style="height: 100%"
            >
              <div class="d-flex flex-column justify-content-center">
                <wameed-no-data
                    icon="nodata-icon"
                    :title="$t('chats.no_data')"
                    :subTitle="$t('chats.no_data_text')"
                />
              </div>
            </div>
          </template>
        </chat-window>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import ChatWindow from 'vue-advanced-chat';
import 'vue-advanced-chat/dist/vue-advanced-chat.css';
import WameedNoData from '@/components/WameedNoData.vue';
import WameedBtn from '@/components/WameedBtn.vue';
import PageHeader from '@/components/PageHeader.vue';

import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import moment from 'moment';

export default {
  components: {
    ChatWindow,
    WameedNoData,
    WameedBtn,
    PageHeader,
  },
  data: () => ({
    rooms: [],
    messages: [],
    messagesLoaded: false,
    loadingRooms: true,
    admin: null,
    currentUserId: '',
  }),

  computed: {
    ...mapGetters({
      getProfileInfo: 'getProfileInfo',
    }),
  },
  watch: {
    async getProfileInfo(userData) {
      this.currentUserId = userData.id;
      this.admin = userData;
      await this.getChat();
    },
  },

  methods: {
    // start
    async getChat() {
      moment.locale('en');
      const rooms = [];
      const db = getFirestore();
      // this.$store.commit('loadingStart', null);
      const q = query(
          collection(db, 'convrsations'),
          where('isConvrsationWithAdmin', '==', true),
      );

      await getDocs(q).then(querySnapshot => {
        querySnapshot.forEach(doc => {

          rooms.push({
            roomId: doc.data().id,
            roomName: this.member(doc.data()).name,
            index: new Date(doc.data().lastMessageDate),
            avatar: this.member(doc.data()).photo,
            unreadCount: this.unreadMessage(doc.data()),
            users: [
              {
                _id: parseInt(this.member(doc.data()).id),
                username: this.member(doc.data()).name,
                avatar: this.member(doc.data()).photo,
              },
              {
                _id: this.admin.id,
                username: this.admin.name,
              },
            ],
            lastMessage: {
              content: doc.data().lastMessage,
              senderId: parseInt(this.member(doc.data()).id),
              username: this.member(doc.data()).name,
              timestamp: new Intl.DateTimeFormat('default', {
                hour12: true,
                hour: 'numeric',
                minute: 'numeric',
              }).format(new Date(doc.data().lastMessageDate)),
            },
          });
        })
        this.rooms = rooms;
        // this.$store.commit('loadingFinish');
        // this.getAllRooms();
      });
    },

    async getAllRooms() {
      const db = await getFirestore();
      const convrsations = query(
          collection(db, 'convrsations'),
          where('isConvrsationWithAdmin', '==', true),
      );

      onSnapshot(convrsations, querySnapshot => {
        const rooms = [];
        querySnapshot.forEach(doc => {

            rooms.push({
              roomId: doc.data().id,
              roomName: this.member(doc.data()).name,
              index: new Date(doc.data().lastMessageDate),
              avatar: this.member(doc.data()).photo,
              unreadCount: this.unreadMessage(doc.data()),
              users: [
                {
                  _id: parseInt(this.member(doc.data()).id),
                  username: this.member(doc.data()).name,
                  avatar: this.member(doc.data()).photo,
                },
                {
                  _id: this.admin.id,
                  username: this.admin.name,
                },
              ],
              lastMessage: {
                content: doc.data().lastMessage,
                senderId: parseInt(this.member(doc.data()).id),
                username: this.member(doc.data()).name,
                timestamp: new Intl.DateTimeFormat('default', {
                  hour12: true,
                  hour: 'numeric',
                  minute: 'numeric',
                }).format(new Date(doc.data().lastMessageDate)),
              },
            });

        });
        this.rooms = rooms;
      });
    },


    async fetchMessages({room, options}) {
      // this.messagesLoaded = false;
      // const userId = room.roomId.split('-')[1];
      moment.locale('en');
      const db = await getFirestore();
      // await updateDoc(doc(db, 'convrsations', room.roomId), {
      //   unreadMessagesMemberA: 0,
      // });
      await setDoc(
          doc(db, 'convrsations', room.roomId.toString()),
          {
            id: room.roomId,
            isAdminConvrsationWithCompany: true,
            isConvrsationWithAdmin: true,
            lastMessage: '',
            lastMessageDate: moment().format(),
            memberB: {
              id: room.roomId,
              name: this.admin.name,
              photo: this.admin.image,
            },
            members: [room.roomId],
            unreadMessagesMemberB: 0,
          },
          {merge: true},
      );
      this.getRoom(room);
      this.messagesLoaded = true;
    },
    async getRoom(room) {
      const db = await getFirestore();
      const convrsations = doc(db, 'convrsations', room.roomId);
      const snapshot = await getDoc(convrsations);

      if (snapshot.exists()) {
        const messageRef = query(
            collection(db, 'convrsations', room.roomId, 'messages'),
            orderBy('created_at', 'asc'),
        );
        onSnapshot(messageRef, querySnapshot => {
          const messages = [];
          querySnapshot.forEach(doc => {
            const msg = doc.data();
            const message = {
              _id: doc.id,
              content: msg.body,
              senderId: parseInt(msg.sender_id),
              username: parseInt(msg.sender_id),
              timestamp: new Intl.DateTimeFormat('default', {
                hour12: true,
                hour: 'numeric',
                minute: 'numeric',
              }).format(new Date(msg.created_at)),
              date: new Date(msg.created_at).toDateString(),
            };
            messages.push(message);
          });
          this.messages = messages;
        });
      } else {
        this.messages = [];
      }
    },
    async sendMessage(message) {
      moment.locale('en');
      const db = await getFirestore();

      const images = [];
      const formData = new FormData();
      if ('files' in message && message.files != null) {


        for (let i = 0; i < message.files.length; i++) {
          const file = new File(
              [message.files[i].blob],
              `${message.files[i].name}.${message.files[0].extension}`,
              {
                type: message.files[i].type,
                lastModified: new Date().getTime(),
              },
          );
          formData.append('file', file);
          await this.$store.dispatch('uploadFileChat', formData).then(() => {
            images.push(this.$store.getters.getUploadedFileLink);
          });
        }
        images.forEach(image => {
          addDoc(collection(db, 'convrsations', message.roomId, 'messages'), {
            body: image,
            convrsationId: message.roomId,
            created_at: moment().format(),
            sender_id: this.currentUserId.toString(),
            type: 'image',
          });
        });
      } else {


        await addDoc(
            collection(db, 'convrsations', message.roomId, 'messages'),
            {
              body: message.content,
              convrsationId: message.roomId,
              created_at: moment().format(),
              sender_id: this.currentUserId.toString(),
              type: 'text',
            },
        );
      }
    },
    member(data) {
      // const isAdmin = this.compareId(data.id);
      // if (isAdmin) {
      //   return data.memberB;
      // }
      return data.memberA;
    },
    unreadMessage(data) {
      const isAdmin = this.compareId(data.id);
      if (isAdmin) {
        return data.unreadMessagesMemberA;
      }
      return data.unreadMessagesMemberB;
    },
    compareId(id) {
      const result = id.split('-')[0] === this.currentUserId.toString();
      return result;
    },
  },
  async created() {
    if (this.getProfileInfo != null) {
      this.admin = this.getProfileInfo;
      this.currentUserId = this.getProfileInfo.id;
      await this.getChat();
    }
  },
 async mounted() {
    document
        .querySelectorAll('.vac-svg-button')[0]
        .classList.add('chat-file-button');
    document
      .querySelectorAll('.vac-svg-button')[1]
      .classList.add('chat-send-button');
    const db = await getFirestore();
    if (this.$route.params.userInfo) {
      const roomId = this.$route.params.userInfo.id.toString();
      const members = [
        this.$route.params.userInfo.id.toString(),
      ];
      const memberA =  {
          id: this.$route.params.userInfo.id.toString(),
          name: this.$route.params.userInfo.name,
          photo: this.$route.params.userInfo.avatar,
        };


      await setDoc(
        doc(db, 'convrsations', roomId),
        {
          id: roomId,
          isAdminConvrsationWithCompany: true,
          isConvrsationWithAdmin: true,
          lastMessage: '',
          lastMessageDate: moment().format(),
          memberA,
          members,
          unreadMessagesMemberA: 0,
          unreadMessagesMemberB: 0,
        },
        { merge: true },
      );
    }
  },
};
</script> 

